module.exports = {
  gridTemplateColumns: ["1fr", null, "1fr 1fr"],
  pt: 50,
  content: {
    height: [300, 450, 550, 700],
    p: 3,

    gridGap: [3, 4],
  },
  background: {
    height: [300, 450, 550, 700],
    overlay: {
      height: [300, 450, 550, 700],
      opacity: [0.83, null, 0.5],
      backgroundColor: "#fff",
    },
  },
}
