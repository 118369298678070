module.exports = {
  backgroundColor: "primary",
  color: "white",
  container: {
    pt: [6, 8],
    pb: 6,
    pr: 3,
    pl: 3,
    blockquote: {
      pt: [4, 5],
      pb: [4, 5],
    },
    cite: {
      mb: 2,
    },
  },
}
