const header = require("./header")
const hero = require("./hero")
const features = require("./features")
const testimonials = require("./testimonials")
const services = require("./services")
const footer = require("./footer")
const us = require("./us")

export default {
  breakpoints: ["40em", "56em", "64em", "75em", "95em"],
  fonts: {
    primary: '"Open Sans", Arial, Helvetica, sans-serif',
    secondary: '"Montserrat", sans-serif',
  },
  colors: {
    text: "#767d",
    background: "#FFF",
    primary: "#e53a53",
    // primary: "#e11936",
    secondary: "#002661",
    // secondary: "#25a2c3",
    white: "#FFF",
  },
  fontWeights: {
    regular: 300,
    medium: 400,
  },
  text: {
    paragraph: {
      fontWeight: "medium",
      fontSize: [1, 2, 3],
      letterSpacing: 0.3,
    },
    quote: {
      fontWeight: "regular",
      fontFamily: "primary",
      fontSize: [2, null, 3],
      fontStyle: "italic",
      letterSpacing: 0.3,
    },
    heading: {
      fontFamily: "secondary",
      color: "secondary",
      fontSize: [4, 5],
      letterSpacing: 0.7,
      lineHeight: "1.2em",
    },
    title: {
      fontFamily: "secondary",
      color: "white",
      fontSize: [3, 4, 5],
      letterSpacing: 0.5,
    },
    subtitle: {
      fontFamily: "secondary",
      color: "secondary",
      fontSize: 3,
      letterSpacing: 0.5,
      lineHeight: "1.1em",
    },
    cite: {
      author: {
        fontSize: 4,
        fontStyle: "normal",
        fontWeight: "medium",
      },
      service: {
        fontSize: 2,
        fontStyle: "normal",
        fontWeight: "regular",
      },
    },
  },
  space: [0, 4, 8, 16, 32, 48, 64, 80, 96],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 80],
  sizes: {
    container: 1200,
  },

  buttons: {
    primary: {
      textTransform: "uppercase",
      fontFamily: "secondary",
      letterSpacing: 2,
      width: ["90%", "250px"],
      m: "auto",
      outline: "none",
    },
    menu: {
      ml: 2,
      backgroundColor: "transparent",
      color: "primary",
      borderColor: "primary",
      borderStyle: "solid",
      borderWidth: 1,
      textTransform: "uppercase",
      fontFamily: "secondary",
      fontSize: 1,
      letterSpacing: 0,
      outline: "none",
    },
  },

  styles: {
    root: {
      textAlign: "center",
      fontFamily: "primary",
    },
    header,
    hero,
    features,
    testimonials,
    services,
    footer,

    us,
  },
}
