module.exports = {
  p: 3,
  pt: 85,
  title: {
    h1: {
      pt: 5,
      pb: 2,
      color: "primary",
    },
    p: {
      pb: 4,
    },
  },
  image: {
    height: [150, 250, 350],
  },
  copy: {
    textAlign: "left",
    p: {
      mt: 5,
      mb: 5,
      fontSize: [2, 3, 4],
    },
  },
}
