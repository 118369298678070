module.exports = {
  p: 3,
  mt: 5,
  mb: 5,
  gridTemplateColumns: ["1fr", "1fr 1fr 1fr"],
  gridTemplateRows: [null, "1fr"],
  firstList: {
    textAlign: [null, "end"],
    ml: [0, 1, 7],
    gridGap: [4, 0],
  },
  lastList: {
    textAlign: [null, "start"],
    mr: [0, 1, 7],
    gridGap: [4, 0],
  },
  h2: {
    mb: 2,
    alignSelf: "end",
  },
  image: {
    m: 4,
  },
}
