module.exports = {
  backgroundColor: "secondary",
  color: "white",
  container: {
    p: 3,
    pt: 5,
    pb: 3,
  },
  contact: {
    mt: 4,
    gridTemplateColumns: ["1fr", "1fr 1fr", "auto auto auto"],
    display: "grid",
    gridGap: 3,
    span: {
      gridColumnStart: [1, null, 3],
      gridColumnEnd: [2, 3, 4],
    },
  },
  "a, span": {
    alignSelf: "center",
    textDecoration: "none",
    color: "white",
    fontSize: 2,
    fontWeight: "regular",
    svg: {
      mr: 2,
      color: "primary",
    },
  },
  copyright: {
    borderTop: "1px solid rgba(255, 255, 255, 0.25)",
    fontSize: "12px !important",
    a: {
      fontSize: "12px !important",
      fontWeight: "medium",
    },
    mt: 4,
    pt: 3,
  },
}
