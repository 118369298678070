module.exports = {
  pt: [6, 8],
  pb: [6, 8],
  pr: 3,
  pl: 3,
  gridGap: 5,
  gridTemplateColumns: [null, , null, "1fr 1fr 1fr"],

  card: {
    border: "solid 1px #dee1e3",
    borderRadius: 8,
    overflow: "hidden",
    mr: [0, 8, 0],
    ml: [0, 8, 0],
  },
  image: {
    height: [200, 400, 200],
  },

  h2: {
    mt: 4,
    mb: 3,
  },
  p: { mb: 3, ml: 4, mr: 4 },
  button: {
    mb: 4,
  },
}
