module.exports = {
  position: "fixed",
  backgroundColor: "white",
  zIndex: 100,
  width: "100vw",
  display: "grid",
  height: 65,
  boxShadow: "2px 2px 2px rgba(68,68,68,0.2)",
  container: {
    p: 3,
    gridTemplateColumns: ["35px auto auto", "200px 1fr 200px"],
  },
  icon: {
    color: "secondary",
    width: 35,
    height: 40,
  },
  brand: {
    display: "grid",
    justifyContent: "center",
    svg: {
      width: 130,
    },
  },
  drawer: {
    p: 3,
    height: "100%",
    gridTemplateRows: "50px 1fr",
    close: {
      height: 65,
      textAlign: "right",
      svg: {
        color: "secondary",
        width: 35,
        height: 40,
      },
    },
    menu: {
      p: 0,
      alignSelf: "center",
      listStyle: "none",
      fontFamily: "secondary",
      display: "grid",
      gridGap: 2,
      a: {
        color: "secondary",
        "&.active": {
          color: "primary",
        },
      },
    },
    social: {
      a: {
        color: "secondary",
      },
    },
  },
}
