// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-flete-cdmx-js": () => import("./../src/pages/flete-cdmx.js" /* webpackChunkName: "component---src-pages-flete-cdmx-js" */),
  "component---src-pages-fletes-atizapan-js": () => import("./../src/pages/fletes-atizapan.js" /* webpackChunkName: "component---src-pages-fletes-atizapan-js" */),
  "component---src-pages-fletes-cdmx-js": () => import("./../src/pages/fletes-cdmx.js" /* webpackChunkName: "component---src-pages-fletes-cdmx-js" */),
  "component---src-pages-fletes-estado-de-mexico-js": () => import("./../src/pages/fletes-estado-de-mexico.js" /* webpackChunkName: "component---src-pages-fletes-estado-de-mexico-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nosotros-js": () => import("./../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */)
}

